
import { defineComponent } from "vue";
// import { skillsData } from "./mock_data_skills";

export default defineComponent({
  props: {
    locked: {
      default: false,
    },
  },
  computed: {
    companyColor(): any {
      const defaultCompanyColor = "#000000";
      const user: any = this.$store.getters["user/user"] || {};
      if (user && user.color) {
        return user.color;
      }
      return defaultCompanyColor;
    },
    skillsLoaded(): boolean {
      return this.$store.getters["coreValuesAnalyzer/skillsLoaded"];
    },
    skillsData(): any {
      return this.$store.getters["coreValuesAnalyzer/skillsData"];
    },
  },
  data() {
    return {
      radarOverallChart: {},
      /*
      loadingAllData: false,
      skillsData: {
        textFooter: "",
        skills: [
          // {
          //   id: "",
          //   skillLabel: "",
          //   skillDescription: "",
          //   skillNotes: [""],
          // },
        ] as any,
      },
      */
    };
  },
  mounted() {
    // console.log(skillsData, "skillsData");
    // this.loadingAllData = true;
    // setTimeout(() => {
    //   this.loadingAllData = false;
    //   this.skillsData = skillsData;
    // }, 3000);
  },
  methods: {
    log(value: any) {
      console.log(value);
    },
  },
});
