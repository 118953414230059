
import { defineComponent } from "vue";
// import { questionsData } from "./mock_data_questions";

export default defineComponent({
  props: {
    locked: {
      default: false,
    },
  },
  computed: {
    companyColor(): any {
      const defaultCompanyColor = "#1B1C1E";
      const user: any = this.$store.getters["user/user"] || {};
      if (user && user.color) {
        return user.color;
      }
      return defaultCompanyColor;
    },
    questionsLoaded(): boolean {
      return this.$store.getters["coreValuesAnalyzer/questionsLoaded"];
    },
    questionsData(): any {
      return this.$store.getters["coreValuesAnalyzer/questionsData"];
    },
  },
  data() {
    return {
      radarOverallChart: {},
      loadingAllData: false,
      // questionsData: {
      //   questionsData: [
      //     {
      //       id: "",
      //       questionType: "",
      //       questionLabel: "",
      //       skillDescription: "",
      //     },
      //   ],
      // },
    };
  },
  mounted() {
    // console.log(questionsData, "questionsData");
    // this.loadingAllData = true;
    // setTimeout(() => {
    //   this.loadingAllData = false;
    //   this.questionsData = questionsData;
    // }, 3000);
  },
  methods: {
    log(value: any) {
      console.log(value);
    },
  },
});
