
import { defineComponent } from "vue";
// import { microlearningData } from "./mock_data_microlearing";

export default defineComponent({
  props: {
    locked: {
      default: false,
    },
  },
  computed: {
    companyColor(): any {
      const defaultCompanyColor = "#1B1C1E";
      const user: any = this.$store.getters["user/user"] || {};
      if (user && user.color) {
        return user.color;
      }
      return defaultCompanyColor;
    },
    microlearningLoaded(): boolean {
      return this.$store.getters["coreValuesAnalyzer/microlearningLoaded"];
    },
    microlearningData(): any {
      return this.$store.getters["coreValuesAnalyzer/microlearningData"];
    },
  },
  data() {
    return {
      radarOverallChart: {},
      loadingAllData: false,
    };
  },
  mounted() {
    // this.loadingAllData = true;
    // setTimeout(() => {
    //   this.loadingAllData = false;
    //   this.microlearningData = microlearningData;
    // }, 3000);
  },
  methods: {
    log(value: any) {
      console.log(value);
    },
    nl2br(value: string) {
      return value.replace(/([^>])\n/g, "$1<br />\n");
    },
  },
});
