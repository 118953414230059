
import { defineComponent } from "vue";
// import Chart from "chart.js/auto";
// import { auditData } from "./mock_data_audit";

export default defineComponent({
  computed: {
    companyColor(): any {
      const defaultCompanyColor = "#1B1C1E";
      const user: any = this.$store.getters["user/user"] || {};
      if (user && user.color) {
        return user.color;
      }
      return defaultCompanyColor;
    },
    loadingAllData(): boolean {
      return this.$store.getters["coreValuesAnalyzer/loading"];
    },
    overAllData(): any {
      return this.$store.getters["coreValuesAnalyzer/auditData"];
    },
    loadingPercentage(): number {
      return this.$store.getters["coreValuesAnalyzer/loadingPercentage"];
    },
  },
  data() {
    return {
      radarOverallChart: {},
      /*
      loadingAllData: false,
      //*/
      // loadingPercentage: 0,
      /*
      overAllData: {
        overAllPercentage: 0,
        dataByCoreValue: [
          {
            coreValueLabel: "",
            addedTime: "",
            id: "",
            clarity: {
              percentage: 0,
              dataContent: [
                {
                  header: "",
                  percentage: 0,
                  statusLabel: "",
                  content: "",
                },
              ],
            },
            practical: {
              percentage: 0,
              dataContent: [
                {
                  header: "",
                  percentage: 0,
                  statusLabel: "",
                  content: "",
                },
              ],
            },
            bestPractice: {
              percentage: 0,
              dataContent: [
                {
                  header: "",
                  percentage: 0,
                  statusLabel: "",
                  content: "",
                },
              ],
            },
            inspiring: {
              percentage: 0,
              dataContent: [
                {
                  header: "",
                  percentage: 0,
                  statusLabel: "",
                  content: "",
                },
              ],
            },
            alternativeOptions: [
              {
                header: "",
                alternativeLabel: "",
              },
            ],
          },
        ],
      },
      //*/
    };
  },
  mounted() {
    // console.log("mounted()");
    // this.loadingAllData = true;
    // TODO: remove mock loading progress
    /*
    for (let i = 0; i <= 100; i++) {
      setTimeout(() => {
        this.loadingPercentage = i;
      }, i * 20);
    }
    //*/
    // TODO: remove mock data
    /*
    setTimeout(() => {
      this.loadingAllData = false;
      this.overAllData = auditData;
    }, 99000);
    //*/
  },
  methods: {
    // renderOverallChart() {
    //   const radarOverallChart = this.$refs.over_all_chart;
    //   if (radarOverallChart) {
    //     const ctx = (radarOverallChart as any).getContext("2d");
    //     const options = {
    //       responsive: true,
    //       maintainAspectRatio: false,
    //       plugins: {
    //         legend: {
    //           display: false,
    //         },
    //         tooltip: {
    //           enabled: false,
    //         },
    //       },
    //       cutout: "70%",
    //       borderWidth: 0,
    //     };
    //     const percentage = this.overAllData.overAllPercentage;
    //     const datasetsData = [percentage, 100 - percentage];
    //     const datasetsVackgroundColor = [
    //       this.overAllPercentageColorCode(percentage),
    //       "transparent",
    //     ];
    //     const data = {
    //       labels: ["", ""],
    //       datasets: [
    //         {
    //           label: "",
    //           data: datasetsData,
    //           backgroundColor: datasetsVackgroundColor,
    //           hoverOffset: 4,
    //         },
    //       ],
    //     };
    //     this.radarOverallChart = new Chart(ctx, {
    //       type: "doughnut",
    //       data: data,
    //       options: options,
    //     });
    //   }
    // },
    overAllPercentageStyle(value: number) {
      if (value > 79) {
        return "green";
      } else if (value > 59) {
        return "yellow";
      } else {
        return "red";
      }
    },
    imagePathAnalysisSection(title: string, value: number) {
      let image = "red";
      if (value > 79) {
        image = "green";
      } else if (value > 59) {
        image = "yellow";
      }
      return (
        require("@/assets/image/AnalysisSectionIcons/" +
          title +
          "_" +
          image +
          ".png") || ""
      );
    },
    overAllPercentageColorCode(value: number) {
      // #color_code
      if (value > 79) {
        return "#28BE79";
      } else if (value > 59) {
        return "#FBC02D";
      } else {
        return "#CC308A";
      }
    },
    checkOpacityAndColor(value: number, maxBar: number) {
      let color = "#CC308A";
      if (value > 79) {
        color = "#22BC66";
      } else if (value > 59) {
        color = "#FACC24";
      }
      // } else {
      //   color = "#CC308A";
      // }
      if (value < maxBar) {
        color += "50";
      }
      // console.log(`value: ${value}, maxBar: ${maxBar}, opacity: ${opacity}`);

      return `background-color: ${color}`;
    },
    log(value: any) {
      console.log(value);
    },
    checkingNaN(value: number) {
      return isNaN(value) ? "-" : value;
    },
    checkingNaN2(value: number) {
      return isNaN(value) ? "?" : value;
    },
  },
});
